import {getAuthToken} from "../functions/authToken";

const serverUrl = process.env.REACT_APP_API_URL;

export default function getRequestCollectionMethod(text, createdAtFrom, createdAtTo, status, sourcePages, utmSource, saleManager, sorting, page, prepaymentStatus = null, services = [], budget = null) {
    const searchParams = new URLSearchParams({
        text,
        createdAtFrom: createdAtFrom ? createdAtFrom : '',
        createdAtTo: createdAtTo ? createdAtTo : '',
        status,
        sourcePages: sourcePages ?? [],
        utmSource,
        saleManager,
        sorting,
        page,
        prepaymentStatus: prepaymentStatus ? prepaymentStatus : '',
        services: services.join(','),
        budget: budget ?? ''
    });
    const url = new URL(serverUrl + '/api/v2/pc/requests?' + searchParams);

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`,
        }
    }).then(response => response.json());
}
