import * as React from "react";
import { useState, useEffect } from "react";
import getRequestCollectionMethod from "../../api/getRequestCollectionMethod";
import {
    Breadcrumb,
    CommandBar,
    CommandBarButton,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode, Shimmer, ShimmeredDetailsList, ShimmerElementType,
    Stack,
    TextField
} from '@fluentui/react';
import { FontSizes } from '@fluentui/theme';
import RequestCollectionFilter from "./RequestCollectionFilter";
import {useNavigate, useSearchParams} from "react-router-dom";
import Pagination from "../Theme/Pagination";
import getUserCollectionMethod from "../../api/getUserCollectionMethod";
import {tableColumns} from "./tableColumns";

export default function RequestCollection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const storedFilterSelection = localStorage.getItem('REQUEST_FILTER') ? JSON.parse(localStorage.getItem('REQUEST_FILTER')) : {createdAtFrom: null, createdAtTo: null, createdAtFastButtonId: null, status: 'ALL', sourcePage: 'ALL', sourcePages: [], utmSource: 'ALL', text: '', saleManager: 'ALL', sorting: 'CREATED_AT_NEW', services: [], budget: null};
    const [filterSelection, setFilterSelection] = useState(searchParams.get('status') ? {...storedFilterSelection, createdAtFrom: null, createdAtTo: null, createdAtFastButtonId: 'ALL', status: searchParams.get('status'), prepaymentStatus: searchParams.get('prepaymentStatus')} : {...storedFilterSelection, prepaymentStatus: null});
    const [requestCollection, setRequestCollection] = useState({loaded: false, data: {collection: [], count: 0, lastPage: 1}});
    const [page, setPage] = useState(1);
    const [salesManagers, setSalesManagers] = useState({loaded: false, data: []});

    const [breadcrumbs, setBreadcrumbs] = useState([
        { text: 'Brand Hub', key: 'main', href: '/', onClick: (e, item) => {e.preventDefault(); navigate(item.href)} },
        { text: 'Лиды', key: 'leads', href: '/leads', isCurrentItem: true },
    ]);

    if (!filterSelection.sorting) {
        filterSelection.sorting = 'CREATED_AT_NEW';
    }

    useEffect(() => {
        if (!requestCollection.loaded) {
            getRequestCollectionMethod(
                filterSelection.text,
                filterSelection.createdAtFrom,
                filterSelection.createdAtTo,
                filterSelection.status,
                filterSelection.sourcePages,
                filterSelection.utmSource,
                filterSelection.saleManager,
                filterSelection.sorting,
                page,
                filterSelection.prepaymentStatus ?? null,
                filterSelection.services,
                filterSelection.budget
            ).then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                //setSearchParams();
                setRequestCollection({loaded: true, data: response});
            });
        }
        if (!salesManagers.loaded) {
            getUserCollectionMethod('NEWBIZ').then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setSalesManagers({loaded: true, data: response});
            });
        }
    });

    const reloadCollection = (freshFilterSelection) => {
        getRequestCollectionMethod(
            freshFilterSelection.text,
            freshFilterSelection.createdAtFrom,
            freshFilterSelection.createdAtTo,
            freshFilterSelection.status,
            freshFilterSelection.sourcePages,
            freshFilterSelection.utmSource,
            freshFilterSelection.saleManager,
            freshFilterSelection.sorting,
            page,
            null,
            freshFilterSelection.services,
            freshFilterSelection.budget
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setRequestCollection({loaded: true, data: response});
        });
    };

    const renewPage = (page) => {
        setPage(page);
        getRequestCollectionMethod(
            filterSelection.text,
            filterSelection.createdAtFrom,
            filterSelection.createdAtTo,
            filterSelection.status,
            filterSelection.sourcePages,
            filterSelection.utmSource,
            filterSelection.saleManager,
            filterSelection.sorting,
            page,
            filterSelection.prepaymentStatus ?? null,
            filterSelection.services,
            filterSelection.budget
        ).then(response => {
            if (response.error) {
                console.log(response.error.message);
                return;
            }

            setRequestCollection({loaded: true, data: response});
        });
    };

    // useEffect(() => {
    //     if (lazyLoadData.loading) {
    //         getRequestCollectionMethod(null, null, null, null, lazyLoadData.page).then(response => {
    //             if (response.error) {
    //                 console.log(response.error.message);
    //                 return;
    //             }
    //
    //             setLazyLoadData({page: lazyLoadData.page, loading: false});
    //             const requestCollectionLocal = requestCollection.data.concat(response);
    //
    //             setRequestCollection({loaded: true, data: requestCollectionLocal});
    //         });
    //     }
    // }, [lazyLoadData, requestCollection]);

    const requestsCountLastDigit = parseInt(requestCollection.data.count.toString().slice(-1));
    let requestsCountWord = '';
    if (requestsCountLastDigit === 1) {
        requestsCountWord = 'запись';
    } else if (requestsCountLastDigit > 1 && requestsCountLastDigit < 5) {
        requestsCountWord = 'записи';
    } else {
        requestsCountWord = 'записей';
    }

    return (
        <>
            <Breadcrumb
                items={breadcrumbs}
                maxDisplayedItems={10}
                ariaLabel="Навигация"
                overflowAriaLabel="Показать больше"
                style={{marginBottom: 10}}
            />
            {/*<div style={{ fontSize: FontSizes.size20, marginBottom: 20, fontWeight: 600 }}>Лиды</div>*/}
            <RequestCollectionFilter salesManagers={salesManagers.data} filterSelection={filterSelection} setFilterSelection={setFilterSelection} reloadCollection={reloadCollection} />
            {!requestCollection.loaded
                ? <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 18 }]} width={100} shimmerColors={{shimmer: '#fafafa'}} />
                : <div>Найдено: <span style={{fontWeight: 600}}>{requestCollection.data.count}</span> {requestsCountWord}</div>
            }
            {!requestCollection.loaded
                ? <ShimmeredDetailsList
                    setKey="items"
                    items={[]}
                    columns={tableColumns}
                    selectionMode={SelectionMode.none}
                    enableShimmer={true}
                    ariaLabelForShimmer="Загрузка..."
                />
                : requestCollection.data.collection.length === 0
                    ? <div style={{marginTop: 20}}><span style={{fontSize: 24, verticalAlign: 'sub', marginRight: 8}}>🤷‍♂️</span>Лидов по заданным фильтрам не найдено</div>
                    : <>
                        <DetailsList
                            items={requestCollection.data.collection}
                            compact={false}
                            columns={tableColumns}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => item.key}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            // onActiveItemChanged={(item) => navigate('/leads/' + item.id)}
                            onRenderRow={(props, Row) => {
                                return <div style={{cursor: 'pointer'}} onClick={(e) => {
                                    navigate('/leads/' + props.item.id);
                                }}>
                                    <Row {...props} />
                                </div>;
                            }}
                        />
                        <div style={{marginTop: 20}}>
                            <Pagination page={page} lastPage={requestCollection.data.lastPage} renewPage={renewPage} />
                        </div>
                    </>
            }
        </>
    );
};
