import * as React from "react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {Callout, CommandBarButton, DefaultButton, Icon, Modal, Stack, TextField} from "@fluentui/react";
import {useEffect, useState} from "react";
import getRequestFiltersMethod from "../../api/getRequestFiltersMethod";
import {useNavigate} from "react-router-dom";
import exportRequestsToExcelMethod from "../../api/exportRequestsToExcelMethod";
import getCurrentUserMethod from "../../api/getCurrentUserMethod";
import DateRangePicker from "../Shared/DateRangePicker";
import {useBoolean, useId} from "@fluentui/react-hooks";
import Source from "./ViewRequest/Source";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import AddRequest from "./AddRequest";

export default function RequestCollectionFilter({salesManagers, filterSelection, setFilterSelection, reloadCollection}) {
    const [filters, setFilters] = useState({loaded: false, statuses: [], pageNames: [], utmSources: [], sourceTypes: []});
    const navigate = useNavigate();
    const [user, setUser] = useState({contact:{firstName:'',lastName:'',email:''},security:{permissions:[]}});

    const [isFilterVisible, { toggle: toggleIsFilterVisible }] = useBoolean(localStorage.getItem('REQUEST_FILTER_VISIBLE') === '1');
    const filterButtonId = useId('filter-button');

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    useEffect(() => {
        if (user.contact.email.length === 0) {
            getCurrentUserMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUser(response);
            });
        }
    });

    let canSeeAllFilters = false;
    user.security.permissions.forEach((permission) => {
        if (permission.key === 'see_leads' && (permission.type === 'ALL' || permission.type === 'DEFAULT')) {
            canSeeAllFilters = true;
        }
    });

    useEffect(() => {
        if (!filters.loaded) {
            getRequestFiltersMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setFilters({...response, loaded: true});
            });
        }
    });

    const dropdownStyles = {
        dropdown: { width: 185 },
    };

    const pageNames = filters.sourceTypes.concat(filters.pageNames.map((item) => {
        const text = item.replace('Страница ', '').replace('услуги ', '').replace('кейса', 'кейс');

        return {key: item, text: (text.charAt(0).toUpperCase() + text.slice(1))}
    }));
    const statuses = filters.statuses.map((item) => { return {key: item, text: item} });
    const utmSources = filters.utmSources.map((item) => { return {key: item, text: item} });
    const salesManagers2 = salesManagers.map((item) => {
        return {key: item.id, text: item.firstName + ' ' + (item.lastName ?? '')}
    });

    // pageNames.unshift({key: 'ALL', text: 'Все источники'});
    statuses.unshift({key: 'ALL', text: 'Все'});
    utmSources.unshift({key: 'ALL', text: 'Все'});
    salesManagers2.unshift({key: 'ALL', text: 'Все'});

    const sorting = [
        { key: 'CREATED_AT_NEW', text: 'Дата заявки', data: { icon: 'ChevronDownSmall' } },
        { key: 'CREATED_AT_OLD', text: 'Дата заявки', data: { icon: 'ChevronUpSmall' } },
        { key: 'UPDATED_AT_NEW', text: 'Дата изменения', data: { icon: 'ChevronDownSmall' } },
        { key: 'UPDATED_AT_OLD', text: 'Дата изменения', data: { icon: 'ChevronUpSmall' } },
    ];

    const changeFilter = (newFilterSelection) => {
        localStorage.setItem('REQUEST_FILTER', JSON.stringify(newFilterSelection));
        setFilterSelection(newFilterSelection);
        reloadCollection(newFilterSelection);
    };

    const onRenderTitle = (options) => {
        const option = options[0];

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 8, color: 'gray' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const onRenderOption = (option) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {option.data && option.data.icon && (
                    <Icon style={{ marginRight: '8px', fontSize: 8, color: 'gray' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    return (<>
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
        >
            <div style={{padding: 30}}>
                <AddRequest />
            </div>
        </Modal>
        <Stack horizontal  style={{justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 10}}>
            <div>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <TextField value={filterSelection.text ?? null} onChange={(e) => changeFilter({...filterSelection, text: e.target.value})} style={{width: 185}} iconProps={{ iconName: 'Search', style: {color: 'gray'} }} />
                <CommandBarButton id={filterButtonId} onClick={() => {
                    localStorage.setItem('REQUEST_FILTER_VISIBLE', isFilterVisible ? '0' : '1');
                    toggleIsFilterVisible();
                }} iconProps={{ iconName: isFilterVisible ? 'ChevronRight' : 'ChevronDown', style: {fontSize: 12, transition: '0.5s'} }} style={{height: 32}} text={isFilterVisible ? 'Фильтры' : 'Фильтры'} />
                {/*{isFilterVisible && (*/}
                {/*    <Callout*/}
                {/*        role="dialog"*/}
                {/*        gapSpace={0}*/}
                {/*        target={`#${filterButtonId}`}*/}
                {/*        onDismiss={toggleIsFilterVisible}*/}
                {/*        setInitialFocus*/}
                {/*    >*/}
                {/*        <div style={{padding: 20, paddingBottom: 30}}>*/}
                {/*            <Stack horizontal tokens={{ childrenGap: 20 }}>*/}
                {/*                <DateRangePicker*/}
                {/*                    label="Даты"*/}
                {/*                    onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({*/}
                {/*                        ...filterSelection,*/}
                {/*                        createdAtFrom: dateFrom,*/}
                {/*                        createdAtTo: dateTo,*/}
                {/*                        createdAtFastButtonId: fastSelectButtonId*/}
                {/*                    })}*/}
                {/*                    defaultDateFrom={filterSelection.createdAtFrom}*/}
                {/*                    defaultDateTo={filterSelection.createdAtTo}*/}
                {/*                    defaultSelectedButtonId={filterSelection.createdAtFastButtonId}*/}
                {/*                />*/}
                {/*                <Dropdown*/}
                {/*                    placeholder="Все"*/}
                {/*                    label="Статус"*/}
                {/*                    options={statuses}*/}
                {/*                    styles={dropdownStyles}*/}
                {/*                    selectedKey={filterSelection.status}*/}
                {/*                    onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}*/}
                {/*                />*/}
                {/*                {canSeeAllFilters*/}
                {/*                    ? <Dropdown*/}
                {/*                        placeholder="Все источники"*/}
                {/*                        label="Источник"*/}
                {/*                        options={pageNames}*/}
                {/*                        styles={dropdownStyles}*/}
                {/*                        selectedKey={filterSelection.sourcePage}*/}
                {/*                        onChange={(e, option) => changeFilter({...filterSelection, sourcePage: option.key})}*/}
                {/*                    />*/}
                {/*                    : <></>*/}
                {/*                }*/}
                {/*            </Stack>*/}
                {/*            <Stack horizontal tokens={{childrenGap: 20}}>*/}
                {/*                {canSeeAllFilters*/}
                {/*                    ? <Dropdown*/}
                {/*                        placeholder="Все услуги"*/}
                {/*                        label="Услуги"*/}
                {/*                        selectedKeys={filterSelection.services}*/}
                {/*                        multiSelect*/}
                {/*                        options={[*/}
                {/*                            {key: 'Бренд-платформа', text: 'Бренд-платформа'},*/}
                {/*                            {key: 'Название', text: 'Название'},*/}
                {/*                            {key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль'},*/}
                {/*                            {key: 'Дизайн упаковки', text: 'Дизайн упаковки'},*/}
                {/*                            {key: 'Дизайн сайта', text: 'Дизайн сайта'},*/}
                {/*                            {key: 'Разработка сайта', text: 'Разработка сайта'},*/}
                {/*                            {key: 'Дизайн презентации', text: 'Дизайн презентации'},*/}
                {/*                            {key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю'},*/}
                {/*                            {key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей'},*/}
                {/*                            {key: 'PR', text: 'PR'},*/}
                {/*                        ]}*/}
                {/*                        styles={dropdownStyles}*/}
                {/*                        onChange={(e, option) => changeFilter({*/}
                {/*                            ...filterSelection,*/}
                {/*                            services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)*/}
                {/*                        })}*/}
                {/*                    />*/}
                {/*                    : <></>*/}
                {/*                }*/}
                {/*                <Dropdown*/}
                {/*                    placeholder="Все"*/}
                {/*                    label="Менеджер"*/}
                {/*                    options={salesManagers2}*/}
                {/*                    styles={dropdownStyles}*/}
                {/*                    selectedKey={filterSelection.saleManager}*/}
                {/*                    onChange={(e, option) => changeFilter({...filterSelection, saleManager: option.key})}*/}
                {/*                />*/}
                {/*                {canSeeAllFilters*/}
                {/*                    ? <Dropdown*/}
                {/*                        placeholder="Все"*/}
                {/*                        label="Рекл. канал"*/}
                {/*                        options={utmSources}*/}
                {/*                        styles={dropdownStyles}*/}
                {/*                        selectedKey={filterSelection.utmSource}*/}
                {/*                        onChange={(e, option) => changeFilter({...filterSelection, utmSource: option.key})}*/}
                {/*                    />*/}
                {/*                    : <></>*/}
                {/*                }*/}
                {/*            </Stack>*/}
                {/*        </div>*/}
                {/*    </Callout>*/}
                {/*)}*/}

            </Stack>
            </div>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{alignItems: 'center'}}>
                <CommandBarButton onClick={showModal} iconProps={{ iconName: 'Add' }} style={{height: 32}} text="Добавить" />
                <CommandBarButton onClick={() => exportRequestsToExcelMethod(filterSelection.createdAtFrom, filterSelection.createdAtTo, filterSelection.status, filterSelection.sourcePages, filterSelection.utmSource, filterSelection.services)} iconProps={{ iconName: 'ExcelDocument' }} style={{height: 32}} text="Excel экспорт" />
                <Dropdown
                    options={sorting}
                    onRenderTitle={onRenderTitle}
                    onRenderOption={onRenderOption}
                    styles={dropdownStyles}
                    selectedKey={filterSelection.sorting}
                    iconProps={{ iconName: 'Sort', style: {color: 'gray'} }}
                    onRenderCaretDown={() => <Icon iconName="Sort" style={{color: 'gray'}} />}
                    onChange={(e, option) => changeFilter({...filterSelection, sorting: option.key})}
                />
            </Stack>
        </Stack>
            {isFilterVisible ? <Stack tokens={{ childrenGap: 5 }} style={{marginBottom: 20}}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <DateRangePicker
                        label="Даты"
                        onChange={(dateFrom, dateTo, fastSelectButtonId) => changeFilter({
                            ...filterSelection,
                            createdAtFrom: dateFrom,
                            createdAtTo: dateTo,
                            createdAtFastButtonId: fastSelectButtonId
                        })}
                        defaultDateFrom={filterSelection.createdAtFrom}
                        defaultDateTo={filterSelection.createdAtTo}
                        defaultSelectedButtonId={filterSelection.createdAtFastButtonId}
                    />
                    <Dropdown
                        placeholder="Все"
                        label="Статус"
                        options={statuses}
                        styles={dropdownStyles}
                        selectedKey={filterSelection.status}
                        onChange={(e, option) => changeFilter({...filterSelection, status: option.key})}
                    />
                    {canSeeAllFilters
                        ? <Dropdown
                            placeholder="Все источники"
                            label="Источник"
                            options={pageNames}
                            styles={dropdownStyles}
                            selectedKeys={filterSelection.sourcePages ?? []}
                            multiSelect={true}
                            onChange={(e, option) => changeFilter({...filterSelection, sourcePages: option.selected ? [...(filterSelection.sourcePages ?? []), option.key] : (filterSelection.sourcePages ?? []).filter(key => key !== option.key)})}
                        />
                        : <></>
                    }
                    {canSeeAllFilters
                        ?  <Dropdown
                            placeholder="Все бюджеты"
                            label="Бюджет проекта"
                            selectedKey={filterSelection.budget ?? null}
                            options={[
                                { key: null, text: 'Все бюджеты' },
                                { key: '300 000', text: 'до 300 000 руб' },
                                { key: '300 000 - 500 000', text: '300 000 - 500 000 руб' },
                                { key: '500 000 +', text: '500 000 +' },
                            ]}
                            styles={dropdownStyles}
                            onChange={(e, option) => changeFilter({...filterSelection, budget: option.key})}
                        />
                        : <></>
                    }
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    {canSeeAllFilters
                        ? <Dropdown
                            placeholder="Все услуги"
                            label="Услуги"
                            selectedKeys={filterSelection.services}
                            multiSelect
                            options={[
                                {key: 'Бренд-платформа', text: 'Бренд-платформа'},
                                {key: 'Название', text: 'Название'},
                                {key: 'Логотип и фирменный стиль', text: 'Логотип и фирменный стиль'},
                                {key: 'Дизайн упаковки', text: 'Дизайн упаковки'},
                                {key: 'Дизайн сайта', text: 'Дизайн сайта'},
                                {key: 'Разработка сайта', text: 'Разработка сайта'},
                                {key: 'Дизайн презентации', text: 'Дизайн презентации'},
                                {key: 'Гайд по фирменному стилю', text: 'Гайд по фирменному стилю'},
                                {key: 'Оформление фирменных носителей', text: 'Оформление фирменных носителей'},
                                {key: 'PR', text: 'PR'},
                            ]}
                            styles={dropdownStyles}
                            onChange={(e, option) => changeFilter({
                                ...filterSelection,
                                services: option.selected ? [...(filterSelection.services ?? []), option.key] : (filterSelection.services ?? []).filter(key => key !== option.key)
                            })}
                        />
                        : <></>
                    }
                    <Dropdown
                        placeholder="Все"
                        label="Менеджер"
                        options={salesManagers2}
                        styles={dropdownStyles}
                        selectedKey={filterSelection.saleManager}
                        onChange={(e, option) => changeFilter({...filterSelection, saleManager: option.key})}
                    />
                    {canSeeAllFilters
                        ? <Dropdown
                            placeholder="Все"
                            label="Рекл. канал"
                            options={utmSources}
                            styles={dropdownStyles}
                            selectedKey={filterSelection.utmSource}
                            onChange={(e, option) => changeFilter({...filterSelection, utmSource: option.key})}
                        />
                        : <></>
                    }
                </Stack>
            </Stack> : <></>}
        </>
    );
};
